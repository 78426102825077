<template>
  <div id="header" class="w-100 m-0 p-3" style="height: 80px;">
    <b-row>
      <b-col class="text-left ml-5">
        <img src="../../assets/img/logo-valantic.svg" alt=""/>
      </b-col>
      <b-col class="text-center">
        <h2 class="font-weight-bold header-btn">KPI - Cockpit</h2>
      </b-col>
      <b-col class="text-right">
        <div class="ml-4" style="font-size: 30px;">
          <router-link to="/home/user-password" class="mr-2" v-b-tooltip title="User Area">
            <i class="bi bi-person-fill header-btn"/>
          </router-link>
          <span v-b-tooltip title="Logout">
        <i class="bi bi-box-arrow-right header-btn" @click="logout"/>
      </span>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import config from "../../config/config";

export default {
  name: "Header",

  methods: {
    logout() {
      axios.get(config.logoutUrl, {
        headers: {
          'Authorization': 'Bearer ' + this.$session.get('auth-token')
        }
      }).then(() => {
        this.$session.remove('auth-token');
        this.$session.remove('username');
        this.$router.push('/');
      }).catch(() => {});
    },
  }
}
</script>
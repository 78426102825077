import axios from "axios";
import config from "../../../config/config";

export default {
    methods: {
        async getLastSync() {
            return axios.get(config.syncUrl + this.getImportCommand(), {
                headers: {
                    'Authorization': 'Bearer ' + this.$session.get('auth-token')
                }
            }).then(response => {
                this.loadingData = false;
                return response.data.created;
            }).catch(err => {
                if (err.response.status !== 401) {
                    this.makeToast(false, 'An Error occurred. Try again later.')
                }
                return '';
            });
        },
    }
};
<template>
  <div>
    <h4>Pipeline Status</h4>

    <b-row class="mt-4">
      <b-col md="6">
        <b v-if="tableItems.length !== 0">
          Showing: {{ perPage < tableItems.length ? perPage : tableItems.length  }} from total: {{ tableItems.length }} entries.
        </b>
        <b v-else>
          Showing: 0 from total: 0 entries.
        </b>
        <b class="ml-3">Last Sync: {{ lastSync }}</b>
      </b-col>
      <b-col md="3"></b-col>
      <b-col md="3" class="text-right"></b-col>
    </b-row>

    <div v-if="loadingData">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
    <div v-else>
      <b-row class="mt-3">
        <b-col lg="6" class="my-1">
          <b-form-group class="mb-0">
            <b-input-group size="sm">
              <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col lg="3" class="my-1">
          <b-form-group
              label="Per page"
              label-for="per-page-select"
              label-cols-sm="3"
              label-cols-md="3"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
          >
            <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="[10,25,50]"
                size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="3" class="my-1">
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              size="sm"
              class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>

      <b-table
          striped
          hover
          head-variant="dark"
          :items="tableItems"
          :fields="tableFields"
          :sort-by.sync="sortBy"
          sort-icon-right
          label-sort-asc=""
          label-sort-desc=""
          label-sort-clear=""
          @sort-changed="rerenderSubTables"
          :current-page="currentPage"
          :per-page="perPage"
          responsive="sm"
          :filter="filter"
          @filtered="onFiltered"
          class="mt-3"
      >
        <template #cell(vcsName)="data">
          <a :href="data.item.url" target="_blank">{{ data.item.vcsName }}</a>
        </template>

        <template #cell(coverage)="data">
          <h3>{{ data.item.coverage }}</h3>
        </template>

        <template #cell(pipelines)="data">
          <PipelineTable :pipelines="data.item.pipelines" :key="componentId" />
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import config from "../../../config/config";
import PipelineTable from "@/modules/PipelineStatus/components/PipelineTable";
import checkLogin from "@/components/mixins/checkLogin";
import toast from "@/components/mixins/toast";
import sync from "@/components/mixins/sync";

export default {
  name: "PipelineStatus",

  mixins: [checkLogin, toast, sync],

  components: {
    PipelineTable
  },

  data() {
    return {
      totalRows: 1,
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 10,
      componentId: Math.random(),
      pipelineStatusData: null,
      loadingData: true,
      sortBy: 'name',
      tableItems: [],
      tableFields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
          thStyle: {
            width: '20% !important'
          }
        },
        {
          key: 'vcsName',
          label: 'VCS Name',
          sortable: true,
          thStyle: {
            width: '20% !important'
          }
        },
        { key: 'pipelines', label: 'Pipeline Status' },
        { key: 'coverage', label: 'Coverage', tdClass: 'text-center align-middle', sortable: true }
      ],
      lastSync: null,
    }
  },

  async created() {
    await this.getPipelineStatus();
    this.lastSync = await this.getLastSync();
  },

  methods: {
    getImportCommand() {
      return config.pipelineCommand;
    },

    rerenderSubTables() {
      this.componentId = Math.random();
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.rerenderSubTables();
    },

    async getPipelineStatus() {
      this.pipelineStatusData = await this.performApiCall();
      this.prepareTableItems();
    },

    async performApiCall() {
      return axios.get(config.pipelineStatusUrl, {
        headers: {
          'Authorization': 'Bearer ' + this.$session.get('auth-token')
        }
      }).then(response => {
        this.loadingData = false;
        return response.data.projects;
      }).catch(err => {
        if (err.response.status !== 401) {
          this.makeToast(false, 'An Error occurred. Try again later.')
        }
        return [];
      });
    },

    prepareTableItems() {
      this.pipelineStatusData.forEach(item => {
        this.tableItems.push({
          name: item.name,
          vcsName: item.nameWithNamespace,
          url: item.url,
          pipelines: item.pipelines,
          coverage: this.getCoverageFromPipeline(item.pipelines),
        });
      });

      this.totalRows = this.tableItems.length;
    },

    getCoverageFromPipeline(pipelines) {
      let coverage = '-/-';

      if (pipelines && pipelines.length === 0) {
        return coverage;
      }

      pipelines.forEach(pipeline => {
        if (pipeline.branch === 'master') {
          coverage = pipeline.codeCoverage ? pipeline.codeCoverage : '-/-';
        }
      });

      return coverage > 0 ? coverage + '%' : coverage;
    }
  }
}
</script>

<template>
  <div>
    <h3>Change Password</h3>

    <b-alert v-if="showAlert" show :variant="alertVariant" class="mt-3">{{ message }}</b-alert>

    <b-form class="mt-3 w-25">
      <b-input-group class="mb-3">
        <b-input-group-prepend>
          <b-button disabled outli variant="info">
            <i class="bi bi-asterisk" />
          </b-button>
        </b-input-group-prepend>
        <b-form-input
            type="password"
            placeholder="New Password"
            v-model="newPassword"
        />
      </b-input-group>
      <b-input-group class="mb-4">
        <b-input-group-prepend>
          <b-button disabled outli variant="info">
            <i class="bi bi-asterisk" />
          </b-button>
        </b-input-group-prepend>
        <b-form-input
            type="password"
            placeholder="Repeat Password"
            v-model="newPasswordRepeat"
        />
      </b-input-group>
      <b-row>
        <b-col :xs="6">
          <b-button variant="success" class="px-4" @click="changePassword">
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import checkLogin from "@/components/mixins/checkLogin";
import axios from "axios";
import config from "../../../config/config";
import toast from "@/components/mixins/toast";

const ALERT_VARIANT_SUCCESS = 'success';
const ALERT_VARIANT_DANGER = 'danger';

export default {
  name: "UserPassword",

  mixins: [checkLogin, toast],

  data() {
    return {
      newPassword: '',
      newPasswordRepeat: '',
      alertVariant: ALERT_VARIANT_SUCCESS,
      showAlert: false,
      message: null
    }
  },

  methods: {
    changePassword() {
      this.showAlert = false;
      this.alertVariant = ALERT_VARIANT_SUCCESS;
      this.checkPasswords();

      if (!this.showAlert) {
        this.sendRequest();
      }
    },

    checkPasswords() {
      if (this.newPassword.length === 0 || this.newPasswordRepeat.length === 0) {
        this.alertVariant = ALERT_VARIANT_DANGER;
        this.showAlert = true;
        this.message = 'Both fields are required!';
        return;
      }

      if (this.newPassword !== this.newPasswordRepeat) {
        this.alertVariant = ALERT_VARIANT_DANGER;
        this.showAlert = true;
        this.message = 'Passwords do not match!';
        return;
      }

      if (this.newPassword.length < 8) {
        this.alertVariant = ALERT_VARIANT_DANGER;
        this.showAlert = true;
        this.message = 'The Password length muss be at least 8 characters!';
      }
    },

    sendRequest() {
      return axios.post(config.changePasswordUrl, {
        password: this.newPassword,
      }, {
        headers: {
          'Authorization': 'Bearer ' + this.$session.get('auth-token')
        }
      }).then(() => {
        this.showAlert = true;
        this.alertVariant = ALERT_VARIANT_SUCCESS;
        this.message = 'Password successfully changed.'
      }).catch(() => {
        this.makeToast(false, 'An Error occurred. Try again later.')
      });
    }
  }
}
</script>

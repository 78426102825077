export default {
    methods: {
        generateColor() {
            let letters = '0123456789ABCDEF'.split('');
            let color = '#';
            for (let i = 0; i < 6; i++ ) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },

        generateRgbaColor(transparency = 0.5) {
            let r = this.randomInteger(255);
            let g = this.randomInteger(255);
            let b = this.randomInteger(255);
            return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + transparency + ')';
        },

        randomInteger(max) {
            return Math.floor(Math.random()*(max + 1));
        }
    }
};
<template>
  <b-card title="Code Coverage" class="dashboard-coverage-card">
    <hr />
    <CodeCoverageChart v-if="chartSeries.length !== 0" :chart-series="chartSeries" :labels="labels" />
  </b-card>
</template>

<script>
import CodeCoverageChart from "@/modules/Dashboard/components/charts/CodeCoverageChart";
import axios from "axios";
import config from "../../../../config/config";
import colorGenerator from "@/components/mixins/colorGenerator";
import {each, filter} from "lodash";

export default {
  name: "DashboardCodeCoverage",

  components: {CodeCoverageChart},

  mixins: [colorGenerator],

  data() {
    return {
      codeCoverageData: null,
      chartSeries: [],
      labels: []
    }
  },

  async created() {
    await this.getCodeCoverage()
  },

  methods: {
    async getCodeCoverage() {
      this.codeCoverageData = await this.performApiCall();
      this.filterOutEmpty();
      this.aggregateData();
      this.prepareChartSeries();
    },

    async performApiCall() {
      return axios.get(config.codeCoverageUrl, {
        headers: {
          'Authorization': 'Bearer ' + this.$session.get('auth-token')
        }
      }).then(response => {
        this.loadingData = false;
        return response.data.codeCoverageItems;
      }).catch(err => {
        if (err.response.status !== 401) {
          this.makeToast(false, 'An Error occurred. Try again later.')
        }
        this.loadingData = false;
        return [];
      });
    },

    filterOutEmpty() {
      this.codeCoverageData = filter(this.codeCoverageData, item => {
        return item.coverage !== null;
      })
    },

    aggregateData() {
      let aggregatedData = {};

      each(this. codeCoverageData, item => {
        if (!aggregatedData.hasOwnProperty(item.projectId)) {
          aggregatedData[item.projectId] = [];
          aggregatedData[item.projectId].push(item);
        } else {
          aggregatedData[item.projectId].push(item);
        }
      });

      each(aggregatedData, (value, projectId) => {
        aggregatedData[projectId] = aggregatedData[projectId][aggregatedData[projectId].length - 1];
      });

      this.codeCoverageData = aggregatedData;
    },

    prepareChartSeries() {
      let dataset = {
        data: [],
        hoverOffset: 4,
        backgroundColor: []
      };

      this.labels = [];

      each(this.codeCoverageData, item => {
        dataset.data.push(parseFloat(item.coverage))
        dataset.backgroundColor.push(this.generateRgbaColor());
        this.labels.push(item.projectName);
      })

      this.chartSeries = [dataset];
    }
  }
}
</script>
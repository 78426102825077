<template>
  <div>
    <b-col class="text-right p-0">
      <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          align="right"
          size="sm"
          class="my-0"
      ></b-pagination>
    </b-col>

    <b-table
        striped
        hover
        show-empty
        head-variant="dark"
        :items="tableItems"
        :fields="tableFields"
        :sort-by.sync="sortBy"
        sort-icon-right
        label-sort-asc=""
        label-sort-desc=""
        label-sort-clear=""
        :current-page="currentPage"
        :per-page="perPage"
        responsive="sm"
        :filter="filter"
        class="mt-3"
    >
    </b-table>

  </div>
</template>

<script>
export default {
  name: "JiraBugsTable",

  props: {
    issues: {
      required: true,
      type: Array,
    },
  },

  data() {
    return {
      tableFields: [
        { key: 'ticketNumber', label: 'Ticket Number', sortable: true },
        { key: 'title', label: 'Title', sortable: true },
        { key: 'priority', label: 'Priority', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'created', label: 'Created at', sortable: true },
        { key: 'updated', label: 'Updated at', sortable: true }
      ],
      tableItems: [],
      sortBy: 'ticketNumber',
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 5,
      totalRows: this.issues.length,
    }
  },

  created() {
    this.initTable();
  },

  methods: {
    initTable() {
      this.issues.forEach(item => {
        this.tableItems.push({
          ticketNumber: item.number,
          title: item.title,
          priority: item.priority,
          status: item.status,
          created: item.created,
          updated: item.updated
        });
      });
    },
  }
}
</script>
<template>
  <div class="mt-3">
    <div v-for="(value, property) in counts" class="mb-1">
      <div class="float-left propertyLabel">{{ property.toUpperCase() }}</div>
      <div v-for="(count, name) in value" class="float-left mr-1">
        <b-button disabled variant="info" size="sm">
          {{ name }} <b-badge variant="light">{{ count }}</b-badge>
        </b-button>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JiraBugsBadges",

  props: {
    counts: {
      required: true,
      type: Object,
      default: {}
    },
  },
}
</script>
<template>
  <div>
    <div v-if="error">
      <h3>{{ errorMessage }}</h3>
    </div>
    <div v-else>
      <h4>Projects Whitelist</h4>

      <b-row class="mt-4">
        <b-col md="6">
          <b v-if="tableItems.length !== 0">
            Showing: {{ perPage < tableItems.length ? perPage : tableItems.length }} from total: {{ tableItems.length }}
            entries.
          </b>
          <b v-else>
            Showing: 0 from total: 0 entries.
          </b>
        </b-col>
        <b-col md="3" class="text-right">
        </b-col>
        <b-col md="3">
        </b-col>
      </b-row>

      <div v-if="loadingData">
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      </div>

      <div v-else>
        <b-row class="mt-3">
          <b-col lg="6" class="my-1">
            <b-form-group class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                ></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="3" class="my-1"></b-col>
          <b-col lg="3" class="my-1"></b-col>
        </b-row>

        <b-table
            striped
            hover
            show-empty
            small
            head-variant="dark"
            :items="tableItems"
            :fields="tableFields"
            :sort-by.sync="sortBy"
            sort-icon-right
            label-sort-asc=""
            label-sort-desc=""
            label-sort-clear=""
            :current-page="currentPage"
            :per-page="perPage"
            responsive="sm"
            :filter="filter"
            @filtered="onFiltered"
            class="mt-3"
        >
          <template #cell(projectName)="row">
            <a :href="getProjectLink(row.item.projectKey)" target="_blank">{{ row.item.projectName }}</a>
          </template>

          <template #cell(active)="row">
            <b-form-checkbox
                v-if="privileges.update"
                v-model="row.item.active"
                name="check-button"
                switch
                @change="performChange(row.item.projectKey, $event)"
            >
            </b-form-checkbox>

            <div v-else>
              <i v-if="row.item.active" class="bi bi-check-circle text-success"/>
              <i v-else class="bi bi-x-circle text-danger"/>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../../../config/config";
import messages from "@/modules/Whitelist/config/messages";
import axios from "axios";
import {each, find} from "lodash";
import checkLogin from "@/components/mixins/checkLogin";

export default {
  name: "Whitelist",

  mixins: [checkLogin],

  data() {
    return {
      sortBy: 'ticketNumber',
      projectsData: null,
      tableFields: [
        {key: 'projectKey', label: 'Project Key', sortable: true},
        {key: 'projectName', label: 'Project Name', sortable: true},
        {key: 'active', label: 'Active', sortable: false},
      ],
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 100,
      tableItems: [],
      loadingData: true,
      error: false,
      errorMessage: null,
      lastSync: null,
      totalRows: 0,
      privileges: null
    }
  },

  async created() {
    await this.getProjectsList()
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getProjectLink(projectKey) {
      return config.jiraBrowserUrl + projectKey;
    },

    async getProjectsList() {
      let data = await this.performApiCall();

      this.projectsData = data.whitelistProjects;
      this.privileges = data.privileges;
      this.prepareTableItems();
    },

    async performApiCall() {
      this.error = false;

      return axios.get(config.whitelistUrl, {
        headers: {
          'Authorization': 'Bearer ' + this.$session.get('auth-token')
        }
      }).then(response => {
        this.loadingData = false;
        return response.data;
      }).catch(err => {
        if(err.response !== undefined) {
          this.error = true;
          this.errorMessage = config.errorCodes[err.response.status];
          this.loadingData = false;
          if (err.response.status !== 401) {
            this.makeToast(false, 'An Error occurred. Try again later.')
          }
          return [];
        }
      });
    },

    prepareTableItems() {
      this.tableItems = [];

      each(this.projectsData, item => {
        this.tableItems.push({
          projectKey: item.projectKey,
          projectName: item.projectName,
          active: item.active,
        });
      });

      this.totalRows = this.tableItems.length;
    },

    async performChange(projectKey, value) {
      if (value) {
        await this.activateProject(projectKey)
      } else {
        await this.deactivateProject(projectKey)
      }
    },

    makeToast(success = true, message = null) {
      if (!message) {
        message = success ? messages.successMessage : messages.errorMessage
      }

      let variant = success ? messages.successVariant : messages.dangerVariant;
      let text = success ? messages.successText : messages.errorMessage;

      this.$bvToast.toast(message, {
        title: `${text}`,
        variant: variant,
        solid: true,
        autoHideDelay: 1000
      });
    },

    revertCheckboxIfNotSuccess(projectKey, success) {
      if (!success) {
        let row = find(this.tableItems, item => {
          return item.projectKey === projectKey
        });

        row.active = !row.active;
      }
    },

    async activateProject(projectKey) {
      return axios.put(config.whitelistUrl + '/' + projectKey, {}, {
        headers: {
          'Authorization': 'Bearer ' + this.$session.get('auth-token')
        }
      }).then(response => {
        this.makeToast(response.data.isSuccess);
        this.revertCheckboxIfNotSuccess(projectKey, response.data.isSuccess);
      }).catch(err => {
        if (err.response.status !== 401) {
          this.makeToast(false, 'An Error occurred. Try again later.')
        }
      });
    },

    async deactivateProject(projectKey) {
      return axios.delete(config.whitelistUrl + '/' + projectKey, {
        headers: {
          'Authorization': 'Bearer ' + this.$session.get('auth-token')
        }
      }).then(response => {
        this.makeToast(response.data.isSuccess);
        this.revertCheckboxIfNotSuccess(projectKey, response.data.isSuccess);
      }).catch(err => {
        if (err.response.status !== 401) {
          this.makeToast(false, 'An Error occurred. Try again later.')
          return;
        }

        this.makeToast(false, err.response.data.message);
      });
    }
  }
}
</script>
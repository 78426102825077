<template>
  <div>
    <h4>Notification recipients list</h4>

    <b-modal
        id="remove-approval"
        title="Remove approval"
        size="md"
        buttonSize="sm"
        okVariant="danger"
        okTitle="YES"
        cancelTitle="NO"
        :centered="true"
        @ok="removeRecipient"
        @hidden="resetModal"
    >
      Please confirm that you want to remove recipient:
      <p v-if="recipientToRemove" class="mt-2">
        Email: <b>{{ recipientToRemove.email }}</b><br>
        Module: <b>{{ recipientToRemove.module }}</b>
      </p>
    </b-modal>

    <b-modal
        id="add-recipient-modal"
        title="Add new recipient"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        okTitle="Save"
        okVariant="success"
        :centered="true"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
            label="Email"
            label-for="email-input"
            invalid-feedback="Email must be a valid Email-Address"
            :state="emailState"
        >
          <b-input-group class="mb-3">
            <b-input-group-prepend>
              <b-button disabled outline variant="info">
                <i class="bi bi-at" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input
                id="email-input"
                v-model="email"
                :state="emailState"
                :autofocus="true"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group
            label="Module"
            label-for="module-input"
        >
          <b-input-group>
            <b-input-group-prepend>
              <b-button disabled outline variant="info">
                <i class="bi bi-box" />
              </b-button>
            </b-input-group-prepend>
            <b-form-select v-model="module" :options="moduleOptions"></b-form-select>
          </b-input-group>
        </b-form-group>
      </form>
    </b-modal>

    <b-row class="mt-4">
      <b-col md="6">
        <b v-if="tableItems.length !== 0">
          Showing: {{ perPage < tableItems.length ? perPage : tableItems.length }} from total: {{ tableItems.length }}
          entries.
        </b>
        <b v-else>
          Showing: 0 from total: 0 entries.
        </b>
      </b-col>
      <b-col md="3" class="text-right">
      </b-col>
      <b-col md="3">
      </b-col>
    </b-row>

    <div v-if="loadingData">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>

    <div v-else>
      <b-row class="mt-3">
        <b-col lg="6" class="my-1">
          <b-form-group class="mb-0">
            <b-input-group size="sm">
              <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col lg="3" class="my-1"></b-col>
        <b-col lg="3" class="my-1 text-right">
          <b-button
              v-if="privileges.update"
              variant="outline-primary"
              v-b-modal.add-recipient-modal
          >
            Add new recipient
          </b-button>
        </b-col>
      </b-row>

      <b-table
          striped
          hover
          show-empty
          small
          head-variant="dark"
          :items="tableItems"
          :fields="tableFields"
          :sort-by.sync="sortBy"
          sort-icon-right
          label-sort-asc=""
          label-sort-desc=""
          label-sort-clear=""
          :current-page="currentPage"
          :per-page="perPage"
          responsive="sm"
          :filter="filter"
          @filtered="onFiltered"
          class="mt-3"
      >
        <template #cell(actions)="row">
          <i v-if="privileges.update"
             class="bi bi-trash text-danger clickable"
             v-b-tooltip
             title="Remove"
             @click="confirmRemoveRecipient(row.item)"
          />
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import checkLogin from "@/components/mixins/checkLogin";
import axios from "axios";
import config from "../../../config/config";
import {each} from "lodash";
import toast from "@/components/mixins/toast";
import notificationConfig from "@/modules/Notification/config/notificationConfig";
import emailValidator from "@/components/mixins/emailValidator";

export default {
  name: "PipelinesNotification",

  mixins: [
    checkLogin,
    toast,
    emailValidator
  ],

  data() {
    return {
      sortBy: 'recipient',
      recipients: null,
      tableFields: [
        {key: 'email', label: 'Recipient', sortable: true},
        {key: 'module', label: 'Module', sortable: true},
      ],
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 100,
      tableItems: [],
      loadingData: true,
      totalRows: 0,
      privileges: null,
      email: '',
      emailState: null,
      module: 'pipelines',
      moduleOptions: notificationConfig.modules,
      recipientToRemove: {}
    }
  },

  async created() {
    await this.getRecipientList()
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async getRecipientList() {
      let data = await this.performApiCall();
      this.recipients = data.recipients;
      this.privileges = data.hasOwnProperty('privileges') ? data.privileges : {};
      this.showActionsColumn();
      this.prepareTableItems();
      this.loadingData = false;
    },

    showActionsColumn() {
      if (this.privileges.update) {
        this.tableFields.push(
            {key: 'actions', label: 'Actions', sortable: false}
        )
      }
    },

    async performApiCall() {
      this.error = false;

      return axios.get(config.notificationRecipientsUrl, {
        headers: {
          'Authorization': 'Bearer ' + this.$session.get('auth-token')
        }
      }).then(response => {
        return response.data;
      }).catch(err => {
        if (err.response.status !== 401) {
          this.makeToast(false, 'An Error occurred. Try again later.')
        }
        return [];
      });
    },

    prepareTableItems() {
      this.tableItems = [];

      each(this.recipients, item => {
        this.tableItems.push({
          email: item.email,
          module: item.module
        });
      });

      this.totalRows = this.tableItems.length;
    },

    resetModal() {
      this.email = ''
      this.emailState = null
      this.recipientToRemove = {}
    },

    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },

    handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }

      this.addRecipient();

      this.$nextTick(() => {
        this.$bvModal.hide('add-recipient-modal')
      })
    },

    checkFormValidity() {
      const valid = this.$refs.form.checkValidity() && this.validateEmail() !== null;
      this.emailState = valid;
      return valid;
    },

    addRecipient() {
      axios.put(config.updateRecipientsUrl, {
        email: this.email,
        module: this.module
      }, {
        headers: {
          'Authorization': 'Bearer ' + this.$session.get('auth-token')
        }
      })
      .then(() => {
        this.makeToast(true, 'Data successfully saved!');
        this.getRecipientList();
      })
      .catch(err => {
        if (err.response.status !== 401) {
          this.makeToast(false, err.response.data.message);
        }
      });
    },

    confirmRemoveRecipient(item) {
      this.recipientToRemove = item;
      this.$bvModal.show('remove-approval');
    },

    removeRecipient() {
      axios.post(config.updateRecipientsUrl, {
        email: this.recipientToRemove.email,
        module: this.recipientToRemove.module
      },{
        headers: {
          'Authorization': 'Bearer ' + this.$session.get('auth-token')
        }
      }).then(response => {
        this.makeToast(response.data.isSuccess, 'Changes were successfully saved');
        this.getRecipientList();
      }).catch(err => {
        if (err.response.status !== 401) {
          this.makeToast(false, 'An Error occurred. Try again later.')
          return;
        }

        this.makeToast(false, err.response.data.message);
      });
    }
  }
}
</script>
export default {
    methods: {
        makeToast(success = true, message) {
            let variant = success ? 'success' : 'danger';
            let title = success ? 'Success' : 'Error occurred';

            this.$bvToast.toast(message, {
                title: `${title}`,
                variant: variant,
                solid: true,
                autoHideDelay: 1500
            });
        },
    }
};
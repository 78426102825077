<template>
  <b-card title="Bug Total" class="bug-total-card">
    <hr />
    <b-row>
      <b-col class="p-0 bug-total-table-col" md="7">
        <b-table striped hover :items="formattedItems" :fields="tableFields" class="ml-2 bug-total-table">
          <template #cell(heading)="row">
            <span class="font-weight-bold">{{ row.item.heading }}</span>
          </template>

          <template #cell()="data">
            <span v-if="data.index === 1">
              <span v-if="data.item[data.field.key] > 0">
                <i class="bi bi-arrow-up"/>{{data.item[data.field.key] * -1}}%
              </span>
              <span v-else-if="data.item[data.field.key] === 0">
                <i class="bi bi-arrow-left-right"/>{{data.item[data.field.key]}}%
              </span>
              <span v-else>
                <i class="bi bi-arrow-down"/>+{{data.item[data.field.key] * - 1}}%
              </span>
            </span>
            <span v-else>{{data.item[data.field.key]}}</span>
          </template>
        </b-table>
      </b-col>
      <b-col class="text-center p-0" md="5">
        <BugTotalChart :chart-series="[chartSeries]" :labels="chartLabels" v-if="chartSeries"/>
      </b-col>
    </b-row>
    <div class="mt-2 bug-total-legend">
      <div v-for="(item, index) in chartLabels" class="text-left float-left mr-2">
        <i class="bi bi-circle-fill" :style="'color: ' + chartSeries.backgroundColor[index]" />
        {{ item }}
      </div>
    </div>
  </b-card>
</template>

<script>
import axios from "axios";
import config from "../../../../config/config";
import toast from "@/components/mixins/toast";
import {each, filter, map, uniqBy} from 'lodash';
import moment from "moment/moment";
import BugTotalChart from "@/modules/Dashboard/components/charts/BugTotalChart";
import colorGenerator from "@/components/mixins/colorGenerator";

export default {
  name: "BugTotal",
  components: {
    BugTotalChart
  },

  computed: {
    formattedItems () {
      if (!this.tableItems) return []
      return each(this.tableItems, (item, index) => {

        if (index === 1) {
          item._cellVariants = {
            last90Days: this.getCellVariant(item.last90Days),
            last30Days: this.getCellVariant(item.last30Days),
            last7Days: this.getCellVariant(item.last7Days)
          }
        }

        return item
      })
    }
  },

  mixins: [toast, colorGenerator],

  data() {
    return {
      jiraBugsData: null,
      tableFields: [
        { key: 'heading', label: ''},
        { key: 'last90Days', label: 'Letzte 3 Monate', tdClass: 'text-right', thClass: 'text-right'},
        { key: 'last30Days', label: 'Letzter Monat', tdClass: 'text-right', thClass: 'text-right'},
        { key: 'last7Days', label: 'Letzte 7 Tage', tdClass: 'text-right', thClass: 'text-right'},
      ],
      tableItems: [],
      chartSeries: null,
      chartLabels: null
    }
  },

  async created() {
    this.jiraBugsData = await this.performApiCall();
    this.prepareChartLabels();
    this.prepareChartSeries()
    this.prepareTableItems();
  },

  methods: {
    getCellVariant(value) {
      if (parseFloat(value) === 0) {
        return 'warning'
      }

      return parseFloat(value) < 0 ? 'danger' : 'success';
    },

    async performApiCall() {
      return axios.get(config.jiraBugsUrl.replace(/{days}/i, -1), {
        headers: {
          'Authorization': 'Bearer ' + this.$session.get('auth-token')
        }
      }).then(response => {
        this.loadingData = false;
        return response.data;
      }).catch(err => {
        if (err.response.status !== 401) {
          this.makeToast(false, 'An Error occurred. Try again later.')
        }
        this.loadingData = false;
        return [];
      });
    },

    prepareTableItems() {
      this.tableItems = [
        {
          last90Days: this.filterByNumberOfDays(90),
          last30Days: this.filterByNumberOfDays(30),
          last7Days: this.filterByNumberOfDays(7),
          heading: 'Total'
        },
        {
          last90Days: this.getTrendByDays(90),
          last30Days: this.getTrendByDays(30),
          last7Days: this.getTrendByDays(7),
          heading: 'Trend'
        }
      ];
    },

    filterByNumberOfDays(days) {
      return filter(this.jiraBugsData, item => {
        return moment(item.updated, 'DD.MM.YYYY HH:mm:ss') >=
            moment(
                moment().format('DD.MM.YYYY 00:00:00'),
                'DD.MM.YYYY HH:mm:ss'
            ).subtract(days, 'days');
      }).length;
    },

    getTrendByDays(days) {
      const countDays = this.filterByNumberOfDays(days);

      const countPrev = filter(this.jiraBugsData, item => {
        return (
          moment(item.updated, 'DD.MM.YYYY HH:mm:ss') >=
          moment(
              moment().format('DD.MM.YYYY 00:00:00'),
              'DD.MM.YYYY HH:mm:ss'
          ).subtract((days * 2), 'days')
        ) && (
              moment(item.updated, 'DD.MM.YYYY HH:mm:ss') <
              moment(
                  moment().format('DD.MM.YYYY 00:00:00'),
                  'DD.MM.YYYY HH:mm:ss'
              ).subtract(days, 'days')
        );
      }).length;

      const result = 100 - (countDays / countPrev) * 100;

      return result.toFixed(2);
    },

    prepareChartLabels() {
      this.chartLabels = map(uniqBy(this.jiraBugsData, 'status'), 'status');
    },

    prepareChartSeries() {
      this.chartSeries = {
        data: this.getTotalsForChart(),
        backgroundColor: this.getBackgroundsForChart(),
        hoverOffset: 4,
      }
    },

    getTotalsForChart() {
      let totals = [];

      each(this.chartLabels, status => {
        const foundByStatus = filter(this.jiraBugsData, item => {
          return item.status === status;
        });

        totals.push(foundByStatus.length);
      });

      return totals;
    },

    getBackgroundsForChart() {
      let backgrounds = [];

      each(this.chartLabels, () => {
        backgrounds.push(this.generateColor())
      });

      return backgrounds;
    },
  }
}
</script>

import PipelineStatus from "@/modules/PipelineStatus/PipelineStatus";
import JiraBugs from "@/modules/JiraBugs/JiraBugs";
import SyncHistory from "@/modules/Sync/SyncHistory";
import TicketReport from "@/modules/TicketReport/TicketReport";
import StoryCoverage from "@/modules/StoryCoverage/StoryCoverage";
import Whitelist from "@/modules/Whitelist/Whitelist";
import Login from "@/modules/Login/Login";
import Home from "@/components/Home";
import UserPassword from "@/modules/User/UserPassword";
import ForgotPassword from "@/modules/Login/ForgotPassword";
import Notification from "@/modules/Notification/Notification";
import CodeCoverage from "@/modules/CodeCoverage/CodeCoverage";
import Dashboard from "@/modules/Dashboard/Dashboard";
import MailPatterns from "@/modules/MailPatterns/MailPatterns";

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
    },
    {
        path: '/forgot-password',
        name: 'Forgot Password',
        component: ForgotPassword,
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        redirect: '/home/dashboard',
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: Dashboard
            },
            {
                path: 'pipelines',
                name: 'Pipelines',
                component: PipelineStatus
            },
            {
                path: 'jira-bugs',
                name: 'Jira-Bugs',
                component: JiraBugs
            },
            {
                path: 'sync-history',
                name: 'Sync-History',
                component: SyncHistory
            },
            {
                path: 'ticket-report',
                name: 'Ticket Report',
                component: TicketReport
            },
            {
                path: 'story-coverage',
                name: 'Story Coverage',
                component: StoryCoverage
            },
            {
                path: 'whitelist',
                name: 'Project Whitelist',
                component: Whitelist
            },
            {
                path: 'user-password',
                name: 'Change Password',
                component: UserPassword
            },
            {
                path: 'notification-recipients',
                name: 'Notification Recipients',
                component: Notification
            },
            {
                path: 'mail-patterns',
                name: 'Internal Mail Patterns',
                component: MailPatterns
            },
            {
                path: 'code-coverage',
                name: 'Code Coverage Report',
                component: CodeCoverage
            },
        ]
    },
];

export default routes;
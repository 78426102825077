<template>
  <LineChartGenerator v-if="chartSeries.length !== 0"
      :chart-options="options"
      :chart-data="series"
  />
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs'
import 'chartjs-adapter-moment';
import moment from "moment/moment";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LineController,
  LinearScale,
  CategoryScale,
  PointElement,
  TimeScale,
} from 'chart.js'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    LineController,
    CategoryScale,
    TimeScale,
    PointElement
)

export default {
  name: 'CoverageChart',

  components: {
    LineChartGenerator
  },

  props: {
    chartSeries : {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      series: {
        datasets: this.chartSeries
      },
      options: {
        type: 'line',
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'month',
              displayFormats: {
                month: 'MM.YYYY'
              }
            }
          },
          y: {
            min: 0,
            max: 120,
            ticks: {
              callback: function(value, index, ticks) {
                if (value > 100) {
                  return '';
                }

                return value + '%';
              }
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                return moment(context.parsed.x).format('MM.YYYY') + ' - ' + context.parsed.y + '%';
              },
              title: function (context) {
                return context[0].dataset.label;
              },
              afterLabel: function (context) {
                return 'Last pipeline update: ' +
                    moment(context.raw.lastUpdate, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm:ss')
              }
            }
          }
        }
      }
    }
  }
}
</script>

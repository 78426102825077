<template>
  <div>
    <b-table
        :items="tableItems"
        :fields="tableFields"
        v-if="pipelines.length > 0"
        :tbody-tr-class="rowClass"
    >
      <template #cell(status)="data">
        <b-badge variant="dark" class="pipelineBadge">pipeline</b-badge>
        <b-badge :variant="getBadgeVariantByStatus(data.item.status)" class="pipelineBadge pipelineStatus">
          {{ data.item.status }}
        </b-badge>
      </template>
      <template #cell(id)="data">
        <a :href="data.item.url" target="_blank">{{data.item.id }}</a>
      </template>
    </b-table>
    <div v-else>
      No Pipelines found for this Project
    </div>
  </div>
</template>

<script>
const BADGE_MAPPING = {
  'success': 'success',
  'failed': 'danger',
  'manual': 'info'
};

export default {
  name: "PipelineTable",

  props: {
    pipelines: {
      required: true,
      type: Array,
    }
  },

  data() {
    return {
      tableFields: [
        'ref',
        { key: 'id', label: 'ID'},
        'status',
        'lastUpdate'
      ],
      tableItems: []
    }
  },

  created() {
    this.initTable();
  },

  methods: {
    initTable() {
      this.pipelines.forEach(item => {
        this.tableItems.push(
          {
            ref: item.branch,
            id: item.id,
            status: item.status,
            url: item.url,
            lastUpdate: item.updated
          }
        );
      });
    },

    getBadgeVariantByStatus(status) {
      return BADGE_MAPPING.hasOwnProperty(status)
        ? BADGE_MAPPING[status]
        : 'warning';
    },

    rowClass (item, type) {
      if (!item || type !== 'row') {
        return;
      }

      if (item.status === 'failed') {
        return 'row-failed';
      }

      if (item.status === 'success') {
        return 'row-success';
      }
    }
  }
}
</script>
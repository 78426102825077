<template>
  <div>
    <h4>Sync History</h4>

    <div v-if="loadingData">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
    <div v-else>
      <b-table
          striped
          hover
          show-empty
          head-variant="dark"
          :items="tableItems"
          :fields="tableFields"
          :sort-by.sync="sortBy"
          sort-icon-right
          label-sort-asc=""
          label-sort-desc=""
          label-sort-clear=""
          responsive="sm"
          class="mt-3"
      >
      </b-table>
    </div>
  </div>
</template>

<script>
import checkLogin from "@/components/mixins/checkLogin";
import axios from "axios";
import config from "../../../config/config";
import {each} from "lodash";
import toast from "@/components/mixins/toast";

export default {
  name: "SyncHistory",

  mixins: [checkLogin, toast],

  data() {
    return {
      sortBy: 'commandName',
      syncHistoryData: null,
      tableFields: [
        { key: 'commandName', label: 'Importer', sortable: true },
        { key: 'created', label: 'Last Run', sortable: true },
      ],
      tableItems: [],
      loadingData: true,
    }
  },

  async created() {
    await this.getSyncHistory();
  },

  methods: {

    async getSyncHistory() {
      this.syncHistoryData = await this.performApiCall();
      this.prepareTableItems();
    },

    async performApiCall() {
      return axios.get(config.syncHistoryUrl, {
        headers: {
          'Authorization': 'Bearer ' + this.$session.get('auth-token')
        }
      }).then(response => {
        this.loadingData = false;
        return response.data.syncHistory;
      }).catch(err => {
        if (err.response.status !== 401) {
          this.makeToast(false, 'An Error occurred. Try again later.')
        }
        this.loadingData = false;
        return [];
      });
    },

    prepareTableItems() {
      this.tableItems = [];

      each(this.syncHistoryData, item => {
        this.tableItems.push({
          commandName: item.commandName,
          created: item.created,
        })
      });
    },
  }
}
</script>
<template>
  <div class="navigation">
    <b-navbar toggleable type="dark">
      <b-navbar-nav>
        <b-nav-item to="/home/dashboard" @click="toggleActive">
          <i class="bi bi-speedometer2 pe-1 mr-2" /> Dashboard
        </b-nav-item>
        <b-nav-item to="/home/pipelines" @click="toggleActive">
          <i class="bi bi-git pe-1 mr-2" /> Pipeline Status
        </b-nav-item>
        <b-nav-item to="/home/jira-bugs" @click="toggleActive">
          <i class="bi bi-bug-fill pe-1 mr-2" /> Jira Bugs
        </b-nav-item>
        <b-nav-item to="/home/story-coverage" @click="toggleActive">
          <i class="bi bi-journal-text pe-1 mr-2" /> Story Coverage LF/SI
        </b-nav-item>
        <b-nav-item to="/home/ticket-report" @click="toggleActive">
          <i class="bi bi-ticket-perforated pe-1 mr-2" /> Ticket Report
        </b-nav-item>
        <b-nav-item to="/home/whitelist" @click="toggleActive">
          <i class="bi bi-list-check pe-1 mr-2" /> Story Coverage Whitelist
        </b-nav-item>
        <b-nav-item to="/home/notification-recipients" @click="toggleActive">
          <i class="bi bi-envelope-check pe-1 mr-2" /> Notification Recipients
        </b-nav-item>
        <b-nav-item to="/home/mail-patterns" @click="toggleActive">
          <i class="bi bi-people pe-1 mr-2" /> Internal Mail Patterns
        </b-nav-item>
        <b-nav-item to="/home/code-coverage" @click="toggleActive">
          <i class="bi bi-braces pe-1 mr-2" /> Code Coverage Report
        </b-nav-item>
        <b-nav-item to="/home/sync-history" @click="toggleActive">
          <i class="bi bi-clock-history pe-1 mr-2" /> Sync History
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Navigation",

  methods: {
    toggleActive(e) {
      e.target.classList.toggle('active')
    }
  }
}
</script>
<template>
  <div>
    <PolarChart
        :chart-options="options"
        :chart-data="series"
        class="float-left polar-chart"
    />
    <div class="float-left ml-2 polar-chart-legend">
      <div>
        <div v-for="(item, index) in series.labels" class="text-left">
          <i class="bi bi-circle-fill" :style="'color: ' + series.datasets[0].backgroundColor[index]" />
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PolarArea as PolarChart } from 'vue-chartjs'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PolarAreaController,
  ArcElement,
  LinearScale,
  CategoryScale,
  RadialLinearScale,
  PointElement,
  TimeScale,
} from 'chart.js'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    ArcElement,
    PolarAreaController,
    LinearScale,
    CategoryScale,
    RadialLinearScale,
    TimeScale,
    PointElement
)

export default {
  name: "CodeCoverageChart",

  props: {
    chartSeries : {
      type: Array,
      required: true
    },
    labels: {
      type: Array,
      required: true
    }
  },

  components: {
    PolarChart
  },

  data() {
    return {
      series: {
        labels: this.labels,
        datasets: this.chartSeries,
      },
      options: {
        type: 'polarArea',
        responsive: true,
        scale: {
          beginElementAt: 0,
          min: 0,
          max: 100,
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                return context.label + ' - ' +context.parsed.r + '%';
              },
              title: function (context) {
                return context[0].dataset.label;
              },
            }
          },
          legend: {
            // position: 'right'
            display: false
          },
        }
      }
    }
  }
}
</script>

<template>
  <div>
    <h4>Jira Bug Report</h4>
    <b-row class="mt-4">
      <b-col md="3">
        <b-button-group>
          <b-button @click="performButtonClick(7)">7 Days</b-button>
          <b-button @click="performButtonClick(30)">1 Month</b-button>
          <b-button @click="performButtonClick(90)">3 Months</b-button>
          <b-button @click="performButtonClick(180)">MAX</b-button>
        </b-button-group>
      </b-col>
      <b-col md="6">
        <b v-if="tableItems.length !== 0">
          Showing: {{ totalRows > perPage ? perPage : totalRows }} From total: {{ totalRows }} entries.
        </b>
        <b class="ml-3">Last Sync: {{ lastSync }}</b>
      </b-col>
      <b-col md="3" class="text-right">
        <b-button v-if="tableItems.length !== 0" @click="exportFile" variant="outline-primary">
          <i class="bi bi-filetype-csv"></i>
          Export as CSV
        </b-button>
      </b-col>
    </b-row>

    <div v-if="loadingData">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
    <div v-else>
      <b-row class="mt-3">
        <b-col lg="6" class="my-1">
          <b-form-group class="mb-0">
            <b-input-group size="sm">
              <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col lg="3" class="my-1">
          <b-form-group
              label="Per page"
              label-for="per-page-select"
              label-cols-sm="3"
              label-cols-md="3"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
          >
            <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="[10,25,50]"
                size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="3" class="my-1">
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              size="sm"
              class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>

      <b-table
        striped
        hover
        show-empty
        head-variant="dark"
        :items="tableItems"
        :fields="tableFields"
        :sort-by.sync="sortBy"
        sort-icon-right
        label-sort-asc=""
        label-sort-desc=""
        label-sort-clear=""
        :current-page="currentPage"
        :per-page="perPage"
        responsive="sm"
        :filter="filter"
        @filtered="onFiltered"
        class="mt-3"
      >
        <template #cell(projectName)="row">
          <a :href="getProjectLink(row.item.projectKey)" target="_blank">{{ row.item.projectName }}</a>
        </template>

        <template #cell(actions)="row">
          <b-button size="sm" @click="row.toggleDetails">
            {{ row.detailsShowing ? 'Hide' : 'Show' }} Bugs
          </b-button>
        </template>

        <template #row-details="row">
          <b-card class="jiraBugsInnerTable">
            <h4>Bug list for {{ row.item.projectName }}</h4>
            <JiraBugsBadges :counts="row.item.counts" />
            <JiraBugsTable :issues="row.item.issues" />
          </b-card>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "../../../config/config";
import {filter, each, map, uniqBy} from "lodash";
import JiraBugsBadges from "@/modules/JiraBugs/components/JiraBugsBadges";
import JiraBugsTable from "@/modules/JiraBugs/components/JiraBugsTable";
import checkLogin from "@/components/mixins/checkLogin";
import toast from "@/components/mixins/toast";
import sync from "@/components/mixins/sync";

export default {
  name: "JiraBugs",

  mixins:[checkLogin, toast, sync],

  components: {
    JiraBugsTable,
    JiraBugsBadges
  },

  data() {
    return {
      sortBy: 'ticketNumber',
      jiraBugsData: null,
      testedTicketsData: null,
      tableFields: [
        { key: 'projectKey', label: 'Project Key', sortable: true },
        { key: 'projectName', label: 'Project Name', sortable: true },
        { key: 'totalBugs', label: 'Total Bugs', sortable: true },
        { key: 'testedTickets', label: 'Tested Tickets', sortable: true },
        { key: 'createdInternal', label: 'Created Internal', sortable: true },
        { key: 'createdExternal', label: 'Created External', sortable: true },
        { key: 'actions', label: 'Actions'},
      ],
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 10,
      tableItems: [],
      projectsData: {},
      loadingData: true,
      currentDaysSelected: 7,
      lastSync: null,
      totalRows: 0,
    }
  },

  async created() {
    await this.getJiraBugs(7)
    this.lastSync = await this.getLastSync();
  },

  methods: {
    getImportCommand() {
      return config.jiraBugsCommand;
    },

    getProjectLink(projectKey) {
      return config.jiraBrowserUrl + projectKey;
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async performButtonClick(days) {
      if (!this.loadingData) {
        this.currentDaysSelected = days;
        await this.getJiraBugs(days);
      }
    },

    exportFile() {
      window.location.href = config.jiraBugsUrl.replace(
          /{days}/i,
          this.currentDaysSelected + '?format=csv&token=' + this.$session.get('auth-token')
      );
    },

    async getJiraBugs(days) {
      this.jiraBugsData = await this.performApiCall(config.jiraBugsUrl.replace(/{days}/i, days));
      this.testedTicketsData = await this.performApiCall(config.testedTicketsUrl.replace(/{days}/i, days));
      this.prepareTableItems();
    },

    async performApiCall(url) {
      return axios.get(url, {
        headers: {
          'Authorization': 'Bearer ' + this.$session.get('auth-token')
        }
      }).then(response => {
        this.loadingData = false;
        return response.data;
      }).catch(err => {
        if (url.includes('jira-tested-tickets')) {
          this.makeToast(false, 'No tested tickets were found for this time period')
          return [];
        }
        if (err.response.status !== 401) {
          this.makeToast(false, 'An Error occurred. Try again later.')
        }
        this.loadingData = false;
        return [];
      });
    },

    prepareTableItems() {
      this.tableItems = [];

      this.aggregateData();

      each(this.projectsData, (projectInfo, projectKey) => {

        let createdCounts = this.getCreatorCountsForProject(projectKey);

        this.tableItems.push({
          projectKey: projectKey,
          projectName: projectInfo.projectName,
          totalBugs: projectInfo.issues.length,
          testedTickets: this.getTestedTicketsForProject(projectKey).length,
          createdInternal: createdCounts.internal,
          createdExternal: createdCounts.external,
          issues: projectInfo.issues,
          counts: this.filterIssues(projectInfo.issues, ['status', 'priority'])
        })
      });

      this.totalRows = this.tableItems.length;
    },

    getTestedTicketsForProject(projectKey) {
      return filter(this.testedTicketsData, item => {
        return item.projectKey === projectKey
      });
    },

    getCreatorCountsForProject(projectKey) {
      const ticketsForProject = this.getTestedTicketsForProject(projectKey);

      const internal = filter(ticketsForProject, item => {
        return item.isInternal;
      }).length;

      const external = filter(ticketsForProject, item => {
        return !item.isInternal;
      }).length;

      return {
        internal: internal,
        external: external
      }
    },

    aggregateData() {
      this.getProjects();

      each(this.projectsData, (value, key) => {
        this.projectsData[key].issues = filter(this.jiraBugsData, item => {
          return item.projectKey === key;
        });
      });
    },

    getProjects() {
      this.projectsData = {};

      this.jiraBugsData.forEach(item => {
        this.projectsData[item.projectKey] = {
          projectName: item.projectName,
          issues: []
        };
      });
    },

    filterIssues(issues, filterByArray) {
      let result = {};

      each(filterByArray, filterBy => {
        result[filterBy] = {};

        let properties = map(uniqBy(issues, filterBy), filterBy);

        each(properties, item => {
          result[filterBy][item] = filter(issues, issue => {
            return issue[filterBy] === item;
          }).length;
        });
      });

      return result;
    }
  }
}
</script>
<template>
  <div>
    <Header class="header"/>
    <div>
      <Navigation />
    </div>
    <div class="main-view p-4">
      <router-view />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Navigation from "@/components/Navigation";
import checkLogin from "@/components/mixins/checkLogin";

export default {
  name: "Home",

  mixins: [checkLogin],

  components: {
    Navigation,
    Header
  }
}
</script>
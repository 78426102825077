import moment from "moment/moment";

export default {
    methods: {
        getCurrentDates() {
            const startDay = moment(this.lastSync, "DD.MM.YYYY HH:mm:ss")
                .startOf('month')
                .format('DD');

            const endDay = moment(this.lastSync, "DD.MM.YYYY HH:mm:ss").endOf('month').format('DD');
            const month = moment(this.lastSync, "DD.MM.YYYY HH:mm:ss").format('MM');

            return startDay + '.' + month + ' - ' + endDay + '.' + month;
        },
    }
};
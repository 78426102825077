<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <BugTotal />
      </b-col>
      <b-col>
        <DashboardStoryCoverage />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <DashboardPipelines />
      </b-col>
      <b-col>
        <DashboardCodeCoverage />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BugTotal from "@/modules/Dashboard/components/BugTotal";
import DashboardStoryCoverage from "@/modules/Dashboard/components/DashboardStoryCoverage";
import DashboardPipelines from "@/modules/Dashboard/components/DashboardPipelines";
import DashboardCodeCoverage from "@/modules/Dashboard/components/DashboardCodeCoverage";
export default {
  name: "Dashboard",
  components: {DashboardCodeCoverage, DashboardPipelines, DashboardStoryCoverage, BugTotal},
}
</script>
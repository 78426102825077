<template>
  <div class="bg-light min-vh-100 d-flex flex-row align-items-center">
    <b-container>
      <b-row class="justify-content-center">
        <b-col :md="6">
          <b-card-group>
            <b-card class="p-4">
              <b-card-body>
                <b-row class="float-right">
                  <img src="../../../assets/img/logo-valantic.svg" alt=""/>
                </b-row>
                <b-form>
                  <h1>Forgot Password</h1>
                  <p class="text-medium-emphasis">Type your email address</p>
                  <b-alert v-if="error" show variant="danger">{{ errorMessage }}</b-alert>
                  <b-alert v-if="success" show variant="success">{{ successMessage }}</b-alert>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-button disabled outli variant="info">
                        <i class="bi bi-asterisk" />
                      </b-button>
                    </b-input-group-prepend>
                    <b-form-input
                        placeholder="Email"
                        autocomplete="Email"
                        v-model="email"
                    />
                  </b-input-group>
                  <b-row>
                    <b-col :xs="6">
                      <b-button variant="success" class="px-4" @click="resetPassword">
                        Reset password
                      </b-button>
                    </b-col>
                    <b-col :xs="6" class="text-right">
                      <router-link to="/">Back to Login page</router-link>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import config from "../../../config/config";
import emailValidator from "@/components/mixins/emailValidator";

export default {
  name: "ForgotPassword",

  mixins: [emailValidator],

  data() {
    return {
      email: '',
      error: false,
      errorMessage: 'Invalid email format!',
      successMessage: 'A new Password was sent to given Email address. Don`t forget to change it after next login!',
      success: false
    }
  },

  methods: {
    resetPassword() {
      this.error = false;

      if (this.validateEmail() === null) {
        this.error = true;
        return;
      }

      this.sendRequest();
    },

    sendRequest() {
      axios.post(config.resetPasswordUrl, {
        email: this.email
      }).then(() => {
        this.success = true;
      }).catch(() => {});
    }
  }
}
</script>

<template>
  <b-card v-if="totals" :title="'Story Coverage - Total: ' + totals.coverage + '%'" class="story-coverage-card">
    <hr />
    <b-row>
      <b-col class="text-center p-0">
        <StoryCoverageTickets v-if="ticketsChartSeries" :chart-series="[ticketsChartSeries]"/>
      </b-col>
      <b-col class="text-center p-0">
        <StoryCoverageTesting v-if="testingChartSeries" :chart-series="testingChartSeries"/>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {find} from "lodash";
import axios from "axios";
import config from "../../../../config/config";
import toast from "@/components/mixins/toast";
import StoryCoverageTickets from "@/modules/Dashboard/components/charts/StoryCoverageTickets";
import StoryCoverageTesting from "@/modules/Dashboard/components/charts/StoryCoverageTesting";

export default {
  name: "DashboardStoryCoverage",
  components: {StoryCoverageTesting, StoryCoverageTickets},
  mixins: [toast],

  data() {
    return {
      totals: null,
      ticketsChartSeries: null,
      testingChartSeries: null
    }
  },

  async created() {
    await this.getTicketReport();
  },

  methods: {
    async getTicketReport() {
      let data = await this.performApiCall();
      this.prepareTotals(data);

      if (this.totals) {
        this.prepareTicketsChartSeries();
        this.prepareTestingChartSeries();
      }
    },

    async performApiCall() {
      return axios.get(config.storyCoverageUrl, {
        headers: {
          'Authorization': 'Bearer ' + this.$session.get('auth-token')
        }
      }).then(response => {
        this.loadingData = false;
        return response.data;
      }).catch(err => {
        this.loadingData = false;
        if (err.response.status !== 401) {
          this.makeToast(false, 'An Error occurred. Try again later.')
        }
        return [];
      });
    },

    prepareTotals(data) {
      this.totals = find(data, item => {
        return item.projectKey === 'totals';
      });
    },

    prepareTicketsChartSeries() {
      this.ticketsChartSeries = {
        data: [
            this.totals.storyTotal,
            this.totals.bugsTotal,
            this.totals.otherIssues,
        ],
        backgroundColor: [
          'rgb(0, 255, 255)',
          'rgb(255, 0, 255)',
          'rgb(255, 128, 0)'
        ],
        hoverOffset: 4,
      }
    },

    prepareTestingChartSeries() {
      this.testingChartSeries = [
        {
          data: [
            this.totals.storiesTestable,
            this.totals.storiesNonTestable,
          ],
          backgroundColor: [
            'rgb(0, 255, 255)',
            'rgb(255, 0, 255)',
          ],
          hoverOffset: 4,
        },
        {
          data: [
            this.totals.storiesHaveTestcase,
            this.totals.storiesTestable - this.totals.storiesHaveTestcase,
          ],
          backgroundColor: [
            'rgb(138,43,226)',
            'rgb(255,127,36)',
          ],
          hoverOffset: 4,
        }
      ];
    }
  }
}
</script>

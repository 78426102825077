<template>
  <div>
    <h4>
      Story Coverage
      <span v-if="lastSync">({{ getCurrentDates() }})</span>
    </h4>

    <b-row class="mt-4">
      <b-col md="6">
        <b v-if="tableItems.length !== 0">
          Showing: {{ perPage < totalRows ? perPage : totalRows  }} from total: {{ totalRows }} entries.
        </b>
        <b v-else>
          Showing: 0 from total: 0 entries.
        </b>
        <b class="ml-3">Last Sync: {{ lastSync }}</b>
      </b-col>
      <b-col md="3">
      </b-col>
      <b-col md="3" class="text-right">
        <b-button v-if="tableItems.length !== 0" @click="exportFile" variant="outline-primary">
          <i class="bi bi-filetype-csv"></i>
          Export as CSV
        </b-button>
        <b-button v-else disabled variant="outline-primary">
          <i class="bi bi-filetype-csv"></i>
          Export as CSV
        </b-button>
      </b-col>
    </b-row>

    <div v-if="loadingData">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
    <div v-else>
      <b-row class="mt-3">
        <b-col lg="6" class="my-1">
          <b-form-group class="mb-0">
            <b-input-group size="sm">
              <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col lg="3" class="my-1">
          <b-form-group
              label="Per page"
              label-for="per-page-select"
              label-cols-sm="3"
              label-cols-md="3"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
          >
            <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="[10,25,50]"
                size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="3" class="my-1">
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              size="sm"
              class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>

      <b-table
          striped
          hover
          show-empty
          head-variant="dark"
          :items="tableItems"
          :fields="tableFields"
          :sort-by.sync="sortBy"
          sort-icon-right
          label-sort-asc=""
          label-sort-desc=""
          label-sort-clear=""
          :current-page="currentPage"
          :per-page="perPage"
          responsive="sm"
          :filter="filter"
          @filtered="onFiltered"
          class="mt-3"
      >
        <template #cell(coverage)="row">
          <div :class="'font-weight-bold ' + getCoverageColorClass(row.item)">
            {{ row.item.storiesTestable === 0  ? 'n/a' : row.item.coverage }}
            <span v-if="row.item.storiesTestable !== 0">%</span>
          </div>
        </template>

        <template #top-row="data" v-if="totals">
          <b-th variant="info" colspan="2">{{ totals.projectKey.toUpperCase() }}</b-th>
          <b-th variant="info" class="text-right">{{ totals.issuesTotal }}</b-th>
          <b-th variant="info" class="text-right">{{ totals.bugsTotal }}</b-th>
          <b-th variant="info" class="text-right">{{ totals.otherIssues }}</b-th>
          <b-th variant="info" class="text-right">{{ totals.storyTotal }}</b-th>
          <b-th variant="info" class="text-right">{{ totals.storiesAfterQa }}</b-th>
          <b-th variant="info" class="text-right">{{ totals.storiesNonTestable }}</b-th>
          <b-th variant="info" class="text-right">{{ totals.storiesTestable }}</b-th>
          <b-th variant="info" class="text-right">{{ totals.storiesHaveTestcase }}</b-th>
          <b-th variant="info" class="text-right">{{ totals.coverage }} %</b-th>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "../../../config/config";
import { find, filter } from 'lodash'
import checkLogin from "@/components/mixins/checkLogin";
import dates from "@/components/mixins/dates";
import toast from "@/components/mixins/toast";
import sync from "@/components/mixins/sync";

export default {
  name: "StoryCoverage",

  mixins: [checkLogin, dates, toast, sync],

  data() {
    return {
      sortBy: 'projectKey',
      sortDesc: true,
      storyCoverageData: null,
      totals: null,
      tableFields: [
        { key: 'projectKey', label: 'Key', sortable: true, class: 'font-weight-bold text-right'},
        { key: 'projectName', label: 'Project Name', sortable: true, class: 'font-weight-bold text-left'},
        { key: 'issuesTotal', label: 'Tickets total', sortable: true, class: 'text-right' },
        { key: 'bugsTotal', label: 'Bugs total', sortable: true, class: 'text-right' },
        { key: 'otherIssues', label: 'Other issues', sortable: true, class: 'text-right' },
        { key: 'storyTotal', label: 'Stories total', sortable: true, class: 'text-right' },
        { key: 'storiesAfterQa', label: 'Stories nach QA', sortable: true, class: 'text-right' },
        { key: 'storiesNonTestable', label: 'Stories non testable', sortable: true, class: 'text-right' },
        { key: 'storiesTestable', label: 'Stories testable', sortable: true, class: 'text-right' },
        { key: 'storiesHaveTestcase', label: 'Testcases available ', sortable: true, class: 'text-right' },
        { key: 'coverage', label: 'Coverage %', sortable: true, class: 'text-right' },
      ],
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 10,
      tableItems: [],
      loadingData: true,
      lastSync: null,
      totalRows: 1,
    }
  },

  async created() {
    await this.getTicketReport()
    this.lastSync = await this.getLastSync();
  },

  methods: {
    getImportCommand() {
      return config.ticketReportCommand;
    },

    exportFile() {
      window.location.href = config.storyCoverageUrl + '?format=csv&token=' + this.$session.get('auth-token');
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async performApiCall() {
      return axios.get(config.storyCoverageUrl, {
        headers: {
          'Authorization': 'Bearer ' + this.$session.get('auth-token')
        }
      }).then(response => {
        this.loadingData = false;
        return response.data;
      }).catch(err => {
        this.loadingData = false;
        if (err.response.status !== 401) {
          this.makeToast(false, 'An Error occurred. Try again later.')
        }
        return [];
      });
    },

    async getTicketReport() {
      let data = await this.performApiCall();
      this.prepareDatasetWithoutTotals(data);
      this.prepareTotals(data);
      this.prepareTableItems();
    },

    prepareTotals(data) {
      this.totals = find(data, item => {
        return item.projectKey === 'totals';
      });
    },

    prepareDatasetWithoutTotals(data) {
      this.storyCoverageData = filter(data, item => {
        return item.projectKey !== 'totals';
      });
    },

    prepareTableItems() {
      this.tableItems = [];

      this.storyCoverageData.forEach(item => {
        this.tableItems.push({
          projectKey: item.projectKey,
          projectName: item.projectName,
          issuesTotal: item.issuesTotal,
          bugsTotal: item.bugsTotal,
          otherIssues: item.otherIssues,
          storyTotal: item.storyTotal,
          storiesAfterQa: item.storiesAfterQa,
          storiesNonTestable: item.storiesNonTestable,
          storiesTestable: item.storiesTestable,
          storiesHaveTestcase: item.storiesHaveTestcase,
          coverage: item.coverage,
          _cellVariants: { projectKey: 'secondary' }
        });
      });

      this.totalRows = this.tableItems.length;
    },

    getCoverageColorClass(item) {
      if (item.storiesTestable === 0) {
        return 'text-black-50';
      }

      const coverage = parseInt(item.coverage);

      if (coverage >= 80) {
        return 'text-success';
      } else if (coverage >= 60 && coverage < 80) {
        return 'text-warning-custom'
      } else {
        return 'text-danger'
      }
    }
  }
}
</script>
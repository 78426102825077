<template>
  <div>
    <h4>Code Coverage Report</h4>

    <Chart v-if="chartSeries" :chart-series="chartSeries"/>
  </div>
</template>

<script>
import sync from "@/components/mixins/sync";
import toast from "@/components/mixins/toast";
import axios from "axios";
import config from "../../../config/config";
import Chart from "@/modules/CodeCoverage/components/Chart";
import moment from "moment/moment";
import {each, sortBy, filter} from "lodash";
import colorGenerator from "@/components/mixins/colorGenerator";

export default {
  name: "CodeCoverage",

  mixins: [sync, toast, colorGenerator],

  components: {
    Chart
  },

  data() {
    return {
      loadingData: true,
      codeCoverageData: null,
      aggregatedData: null,
      chartSeries: null
    }
  },

  async created() {
    await this.getCodeCoverage()
  },

  methods: {
    getImportCommand() {
      return config.codeCoverageCommand;
    },

    async getCodeCoverage() {
      this.codeCoverageData = await this.performApiCall();
      this.aggregateCodeCoverageData();
      this.chartSeries = this.prepareChartSeries();
    },

    async performApiCall() {
      return axios.get(config.codeCoverageUrl, {
        headers: {
          'Authorization': 'Bearer ' + this.$session.get('auth-token')
        }
      }).then(response => {
        this.loadingData = false;
        return response.data.codeCoverageItems;
      }).catch(err => {
        if (err.response.status !== 401) {
          this.makeToast(false, 'An Error occurred. Try again later.')
        }
        this.loadingData = false;
        return [];
      });
    },

    aggregateCodeCoverageData() {
      let aggregated = {};

      each(this.codeCoverageData, item => {
        if (!aggregated.hasOwnProperty(item.projectId)) {
          aggregated[item.projectId] = [];
        }
      });

      each(aggregated, (value, key) => {
        aggregated[key] = filter(this.codeCoverageData, item => {
          return item.projectId === parseInt(key);
        });
      });

      this.aggregatedData = aggregated;
    },

    prepareChartSeries() {
      let dataSets = [];

      each(this.aggregatedData, projectData => {
        const color = this.generateColor();

        let projectSeries = {
          cubicInterpolationMode: 'monotone',
          label: projectData[0].projectName,
          backgroundColor: color,
          borderColor: color,
          data: []
        };

        each(projectData, coverageItem => {
          if (coverageItem.coverage !== null) {
            projectSeries.data.push({
              x: moment(coverageItem.created, 'DD.MM.YYYY'),
              y: coverageItem.coverage,
              lastUpdate: coverageItem.pipelineLastUpdated
            })
          }
        });

        dataSets.push(projectSeries);
      });

      return this.disableEmpty(dataSets);
    },

    disableEmpty(datasets) {

      each(datasets, dataset => {
        dataset['hidden'] = dataset.data.length === 0;
      })

      return this.sortDatasets(datasets);
    },

    sortDatasets(datasets) {
        return sortBy(datasets, item => {
          return item.data.length === 0;
        })
    },
  }
}
</script>
export default {
    created: function () {
        this.checkLogin()
    },
    methods: {
        checkLogin: function () {
            const token = this.$session.get('auth-token');

            if (token === undefined) {
                this.$router.push('/').catch(err => {});
            }
        }
    }
};
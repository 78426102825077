<template>
  <b-container class="p-0">
    <router-view></router-view>
  </b-container>
</template>

<script>

import axios from "axios";

export default {
  name: 'App',
  components: {
  },

  watch:{
    async $route (to, from){
      if (to.path !== '/') {
        this.$session.set('last-path-' + this.$session.get('username'), to.path);
      }
    }
  },

  created() {
    this.handleAxiosResponse();
  },

  methods: {
    handleAxiosResponse() {
      axios.interceptors.response.use(
          response => response,
          error => {
            if (error.response.status === 401) {
              this.$session.remove('auth-token');
              this.$session.remove('username');
              this.$router.push('/').catch(() => {});
            }

            return Promise.reject(error);
          }
      );
    }
  }
}
</script>

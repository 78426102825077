import App from './App.vue'
import VueRouter from "vue-router";
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueSession from 'vue-session'
import routes from "../config/routes";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import '../assets/styles/custom-styles.scss';

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter);
Vue.use(VueSession)

const router = new VueRouter({
    routes: routes
});

new Vue({
    router,
    render: h => h(App)
}).$mount("#app");
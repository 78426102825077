<template>
  <div>
    <h4>
      Ticket Month Report
      <span v-if="lastSync">({{ getCurrentDates() }})</span>
    </h4>

    <b-row class="mt-4" v-if="!loadingData">
      <b-col md="3">
        <b-form-checkbox
          name="check-button"
          v-model="whitelistFilter"
          @change="filterByWhitelist"
          switch
        >
          Whitelist filter
        </b-form-checkbox>
      </b-col>
      <b-col md="6">
        <b v-if="filteredItems.length !== 0">
          Showing: {{ perPage < filteredItems.length ? perPage : filteredItems.length  }} from total: {{ filteredItems.length }} entries.
        </b>
        <b v-else>
          Showing: 0 from total: 0 entries.
        </b>
        <b class="ml-3">Last Sync: {{ lastSync }}</b>
      </b-col>
      <b-col md="3" class="text-right">
        <b-button v-if="filteredItems.length !== 0" @click="exportFile" variant="outline-primary">
          <i class="bi bi-filetype-csv"></i>
          Export as CSV
        </b-button>
        <b-button v-else disabled variant="outline-primary">
          <i class="bi bi-filetype-csv"></i>
          Export as CSV
        </b-button>
      </b-col>
    </b-row>

    <b-row class="mt-2 mb-2" v-if="!loadingData">
      <b-col>
        <div id="advanced-search" class="mt-2">
          <b-card>
            <b-row>
              <b-col>
                <h4>Advanced Search</h4>
              </b-col>
              <b-col class="text-right">
                <b-button size="sm" @click="resetSearch">Reset search</b-button>
              </b-col>
            </b-row>

            <b-row>
              <b-col v-for="(filterItem, key) in advancedFilter" v-bind:key="key">
                <div  class="mb-2 small">
                  <label class="typo__label text-black-50">{{ filterItem.label }}</label>
                  <Multiselect
                      :id="key"
                      v-model="filterItem.selected"
                      :options="filterItem.options"
                      :multiple="true"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      placeholder="Pick some"
                      label="name"
                      track-by="name"
                      :preselect-first="false"
                      @input="performSearch"
                  >
                  </Multiselect>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <div v-if="loadingData">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>

    <div v-else>
      <b-row class="mt-3">
        <b-col lg="6" class="my-1">
          <b-form-group class="mb-0">
            <b-input-group size="sm">
              <b-form-input
                  id="filter-input"
                  v-model="inputFilter"
                  type="search"
                  placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!inputFilter" @click="inputFilter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col lg="3" class="my-1">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md="3"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="[10,25,50]"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="3" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            align="right"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>

      <b-table
        striped
        hover
        show-empty
        small
        head-variant="dark"
        :items="filteredItems"
        :fields="tableFields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        sort-icon-right
        label-sort-asc=""
        label-sort-desc=""
        label-sort-clear=""
        :current-page="currentPage"
        :per-page="perPage"
        responsive="sm"
        :filter="inputFilter"
        @filtered="onFiltered"
        class="mt-3"
      >
        <template #cell(issueLink)="data">
          <a :href="data.item.issueLink" target="_blank">{{ data.item.issueKey }}</a>
        </template>

        <template #cell(issueTestable)="data">
          <h4>
            <i v-if="data.item.issueTestable" class="bi bi-check-circle text-success"/>
            <i v-else class="bi bi-x-circle text-danger"/>
          </h4>
        </template>

        <template #cell(hasTestCase)="data">
          <h4>
            <i v-if="data.item.hasTestCase" class="bi bi-check-circle text-success"/>
            <i v-else class="bi bi-x-circle text-danger"/>
          </h4>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "../../../config/config";
import {uniqBy, map, each, find, isBoolean} from "lodash";
import checkLogin from "@/components/mixins/checkLogin";
import dates from "@/components/mixins/dates";
import toast from "@/components/mixins/toast";
import Multiselect from 'vue-multiselect';
import sync from "@/components/mixins/sync";

export default {
  name: "TicketReport",

  mixins: [checkLogin, dates, toast, sync],

  components: {
    Multiselect
  },

  data() {
    return {
      sortBy: 'lastUpdated',
      sortDesc: true,
      ticketReportData: null,
      tableFields: [
        { key: 'projectKey', label: 'Project Key', sortable: true },
        { key: 'projectName', label: 'Project Name', sortable: true },
        { key: 'issueKey', label: 'Issue Key', sortable: true },
        { key: 'issueTitle', label: 'Title', sortable: true },
        { key: 'issueLink', label: 'Link', sortable: true },
        { key: 'issuePriority', label: 'Priority', sortable: true },
        { key: 'issueStatus', label: 'Status', sortable: true },
        { key: 'issueTestable', label: 'Is Testable', sortable: true },
        { key: 'hasTestCase', label: 'Has Testcase', sortable: true },
        { key: 'issueType', label: 'Type', sortable: true },
        { key: 'lastUpdated', label: 'Last Updated', sortable: true },
      ],
      availableFilters: [
        'projectKey',
        'projectName',
        'issuePriority',
        'issueStatus',
        'issueTestable',
        'hasTestCase',
        'issueType',
      ],
      filteredItems: [],
      inputFilter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 10,
      tableItems: [],
      loadingData: true,
      lastSync: null,
      totalRows: 1,
      whitelistFilter: false,
      advancedFilter: {},
    }
  },

  async created() {
    await this.getTicketReport()
    this.lastSync = await this.getLastSync();
    this.advancedFilter = this.buildFilterList();
  },

  methods: {
    getImportCommand() {
      return config.ticketReportCommand;
    },

    exportFile() {
      window.location.href = config.ticketReportUrl + '?format=csv&token=' + this.$session.get('auth-token');
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async performApiCall(whitelistFilter = false) {
      let url = config.ticketReportUrl;

      if (whitelistFilter) {
        url += '/whitelistFilter'
      }

      return axios.get(url, {
        headers: {
          'Authorization': 'Bearer ' + this.$session.get('auth-token')
        }
      }).then(response => {
        this.loadingData = false;
        return response.data;
      }).catch(err => {
        this.loadingData = false;
        if (err.response.status !== 401) {
          this.makeToast(false, 'An Error occurred. Try again later.')
        }
        return [];
      });
    },

    async getTicketReport(whitelistFilter = false) {
      this.ticketReportData = await this.performApiCall(whitelistFilter);
      this.prepareTableItems();
      this.filteredItems = this.tableItems;
    },

    prepareTableItems() {
      this.tableItems = [];

      this.ticketReportData.forEach(item => {
        this.tableItems.push({
          projectKey: item.projectKey,
          projectName: item.projectName,
          issueKey: item.issueKey,
          issueTitle: item.issueTitle,
          issueLink: item.issueLink,
          issuePriority: item.issuePriority,
          issueStatus: item.issueStatus,
          issueTestable: item.issueTestable,
          hasTestCase: item.hasTestCase,
          issueType: item.issueType,
          lastUpdated: item.lastUpdated,
        });
      });

      this.totalRows = this.tableItems.length;
    },

    async filterByWhitelist() {
      this.loadingData = true;
      await this.getTicketReport(this.whitelistFilter);
      this.performSearch();
    },

    getFilterOptionsByCol(col) {
      const uniqValues = uniqBy(this.tableItems, col);
      return map(uniqValues, col);
    },

    resetSearch() {
      this.advancedFilter = this.buildFilterList();
      this.filteredItems = this.tableItems;
    },

    buildFilterList() {
      let filterList = {};

      this.availableFilters.forEach(item => {
        filterList[item] = {
          field: item,
          label: find(this.tableFields, field => {
            return field.key === item;
          }).label,
          options: this.getFilterOptions(item),
          selected: []
        };
      });

      return filterList;
    },

    getFilterOptions(filter) {
      const options = this.getFilterOptionsByCol(filter);
      let filterOptions = [];

      options.forEach(option => {
        filterOptions.push({
          name: isBoolean(option)
              ? option ? 'Yes' : 'No'
              : option,
          value: option
        });
      });

      return filterOptions;
    },

    performSearch() {
      this.inputFilter = '';
      const conditions = this.buildConditions();
      this.filteredItems = this.filterData(this.tableItems, conditions);
      this.onFiltered(this.filteredItems)
    },

    filterData(data, conditions) {
      return data.filter((item) => {
        for (let key in conditions) {
          if (item[key] === undefined || !conditions[key].includes(item[key])) {
            return false;
          }
        }
        return true;
      });
    },

    buildConditions() {
      let conditions = {};

      each(this.advancedFilter, (item, id) => {
        const selectedFilters = item.selected;

        if (selectedFilters.length !== 0) {
          conditions[id] = [];

          each(selectedFilters, selected => {
              conditions[id].push(selected.value);
          });
        }
      });

      return conditions;
    },
  }
}
</script>
<template>
  <div>
    <div class="mb-2">Tickets Total</div>
    <div style="display: flex; justify-content: center">
      <DoughnutChart
          :chart-options="options"
          :chart-data="series"
          class="story-coverage-tickets-chart"
      />
    </div>
    <div class="mt-2 story-coverage-tickets-legend">
      <div>
        <div v-for="(item, index) in series.labels" class="text-left">
          <i class="bi bi-circle-fill" :style="'color: ' + chartSeries[0].backgroundColor[index]" />
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Doughnut as DoughnutChart } from 'vue-chartjs'
import 'chartjs-adapter-moment';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  DoughnutController,
  ArcElement,
  LinearScale,
  CategoryScale,
  PointElement,
  TimeScale,
} from 'chart.js'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    ArcElement,
    DoughnutController,
    LinearScale,
    CategoryScale,
    TimeScale,
    PointElement
)

export default {
  name: "StoryCoverageTickets",

  props: {
    chartSeries : {
      type: Array,
      required: true
    }
  },

  components: {
    DoughnutChart
  },

  data() {
    return {
      series: {
        labels: [
          'Story Total',
          'Bug Total',
          'Other Issues',
        ],
        datasets: this.chartSeries
      },
      options: {
        type: 'doughnut',
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false
          },
        }
      }
    }
  }
}
</script>

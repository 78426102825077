<template>
  <div class="bg-light min-vh-100 d-flex flex-row align-items-center">
    <b-container>
      <b-row class="justify-content-center">
        <b-col :md="6">
          <b-card-group>
            <b-card class="p-4">
              <b-card-body>
                <b-row class="float-right">
                  <img src="../../../assets/img/logo-valantic.svg" alt=""/>
                </b-row>
                <b-form>
                  <h1>KPI Cockpit - Login</h1>
                  <p class="text-medium-emphasis">Sign In to your account</p>
                  <b-alert v-if="loginError" show variant="danger">{{ errorMessage }}</b-alert>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-button disabled outli variant="info">
                        <i class="bi bi-person-fill" />
                      </b-button>
                    </b-input-group-prepend>
                    <b-form-input
                        placeholder="Username"
                        autocomplete="username"
                        v-model="username"
                    />
                  </b-input-group>
                  <b-input-group class="mb-4">
                    <b-input-group-prepend>
                      <b-button disabled outli variant="info">
                        <i class="bi bi-asterisk" />
                      </b-button>
                    </b-input-group-prepend>
                    <b-form-input
                        type="password"
                        placeholder="Password"
                        autocomplete="current-password"
                        v-model="password"
                    />
                  </b-input-group>
                  <b-row>
                    <b-col :xs="6">
                      <b-button variant="success" class="px-4" @click="performLogin">
                        Login
                      </b-button>
                    </b-col>
                    <b-col :xs="6" class="text-right">
                      <b-button color="link" @click="redirectToForgotPassword">
                        Forgot password?
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import config from "../../../config/config";

export default {
  name: "Login",

  data() {
    return {
      username: '',
      password: '',
      loginError: false,
      errorMessage: null,
    }
  },

  created() {
    const token = this.$session.get('auth-token');

    if (token !== undefined) {
      this.$router.push('/home').catch(() => {});
    }
  },

  methods: {
    performLogin() {
      this.loginError = false;

      return axios.post(config.loginUrl, {
        email: this.username,
        password: this.password
      }).then(response => {
        this.$session.set('username', this.username);
        this.$session.set('auth-token', response.data.token);
        this.redirectLastPath();
      }).catch(err => {
        this.loginError = true;
        this.errorMessage = err.response.data.message;
      });
    },

    redirectToForgotPassword() {
      this.$router.push('/forgot-password')
    },

    redirectLastPath() {
      if (
          this.$session.has('last-path-' + this.$session.get('username')) &&
          this.$session.get('last-path-' + this.$session.get('username')) !== '/')
      {
        this.$router.push(this.$session.get('last-path-' + this.$session.get('username'))).catch(()=>{});
      } else {
        this.$router.push('/home').catch(()=>{});
      }
    }
  }
}
</script>
<template>
  <div>
    <h4>Internal mail pattern list</h4>

    <b-modal
        id="mail-pattern-remove-approval"
        title="Remove approval"
        size="md"
        buttonSize="sm"
        okVariant="danger"
        okTitle="YES"
        cancelTitle="NO"
        :centered="true"
        @ok="removeMailPattern"
        @hidden="resetModal"
    >
      Please confirm that you want to remove mail Pattern:
      <p v-if="mailPatternToRemove" class="mt-2">
        Mail Pattern: <b>{{ mailPatternToRemove.emailPattern }}</b>
      </p>
    </b-modal>

    <b-modal
        id="add-mail-pattern-modal"
        title="Add new internal Mail pattern"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        okTitle="Save"
        okVariant="success"
        :centered="true"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
            label="Email Pattern (without '@')"
            label-for="email-pattern-input"
            invalid-feedback="Given pattern is not valid. Example: xxx.xxx"
            :state="emailPatternState"
        >
          <b-input-group class="mb-3">
            <b-input-group-prepend>
              <b-button disabled outline variant="info">
                <i class="bi bi-at" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input
                id="email-pattern-input"
                v-model="emailPattern"
                :state="emailPatternState"
                :autofocus="true"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </form>
    </b-modal>

    <b-row class="mt-4">
      <b-col md="6">
        <b v-if="tableItems.length !== 0">
          Showing: {{ perPage < tableItems.length ? perPage : tableItems.length }} from total: {{ tableItems.length }}
          entries.
        </b>
        <b v-else>
          Showing: 0 from total: 0 entries.
        </b>
      </b-col>
      <b-col md="3" class="text-right">
      </b-col>
      <b-col md="3">
      </b-col>
    </b-row>

    <div v-if="loadingData">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>

    <div v-else>
      <b-row class="mt-3">
        <b-col lg="6" class="my-1">
          <b-form-group class="mb-0">
            <b-input-group size="sm">
              <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col lg="3" class="my-1"></b-col>
        <b-col lg="3" class="my-1 text-right">
          <b-button
              v-if="privileges.update"
              variant="outline-primary"
              v-b-modal.add-mail-pattern-modal
          >
            Add new Mail Pattern
          </b-button>
        </b-col>
      </b-row>

      <b-table
          striped
          hover
          show-empty
          small
          head-variant="dark"
          :items="tableItems"
          :fields="tableFields"
          :sort-by.sync="sortBy"
          sort-icon-right
          label-sort-asc=""
          label-sort-desc=""
          label-sort-clear=""
          :current-page="currentPage"
          :per-page="perPage"
          responsive="sm"
          :filter="filter"
          @filtered="onFiltered"
          class="mt-3"
      >
        <template #cell(actions)="row">
          <i v-if="privileges.update"
             class="bi bi-trash text-danger clickable"
             v-b-tooltip
             title="Remove"
             @click="confirmRemoveMailPattern(row.item)"
          />
        </template>
      </b-table>
    </div>

  </div>
</template>

<script>
import checkLogin from "@/components/mixins/checkLogin";
import toast from "@/components/mixins/toast";
import axios from "axios";
import config from "../../../config/config";
import {each} from "lodash";

export default {
  name: "MailPatterns",

  mixins: [
    checkLogin,
    toast,
  ],

  data() {
    return {
      sortBy: 'recipient',
      mailPatternList: null,
      tableFields: [
        {key: 'emailPattern', label: 'Email Pattern', sortable: true},
      ],
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 100,
      tableItems: [],
      loadingData: true,
      totalRows: 0,
      privileges: null,
      emailPattern: '',
      emailPatternState: null,
      mailPatternToRemove: {}
    }
  },

  async created() {
    await this.getMailPatternList()
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async getMailPatternList() {
      let data = await this.performApiCall();
      this.mailPatternList = data.mailPatterns;
      this.privileges = data.hasOwnProperty('privileges') ? data.privileges : {};
      this.showActionsColumn();
      this.prepareTableItems();
      this.loadingData = false;
    },

    showActionsColumn() {
      if (this.privileges.update) {
        this.tableFields.push(
            {key: 'actions', label: 'Actions', sortable: false}
        )
      }
    },

    async performApiCall() {
      this.error = false;

      return axios.get(config.mailPatternsUrl, {
        headers: {
          'Authorization': 'Bearer ' + this.$session.get('auth-token')
        }
      }).then(response => {
        return response.data;
      }).catch(err => {
        if (err.response.status !== 401) {
          this.makeToast(false, 'An Error occurred. Try again later.')
        }
        return [];
      });
    },

    prepareTableItems() {
      this.tableItems = [];

      each(this.mailPatternList, item => {
        this.tableItems.push({
          emailPattern: item.emailPattern,
        });
      });

      this.totalRows = this.tableItems.length;
    },

    confirmRemoveMailPattern(item) {
      this.mailPatternToRemove = item;
      this.$bvModal.show('mail-pattern-remove-approval');
    },

    resetModal() {
      this.emailPattern = ''
      this.emailPatternState = null
      this.mailPatternToRemove = {}
    },

    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },

    removeMailPattern() {
      axios.delete(config.mailPatternUpdateUrl.replace(/{pattern}/i, this.mailPatternToRemove.emailPattern),{
        headers: {
          'Authorization': 'Bearer ' + this.$session.get('auth-token')
        }
      }).then(response => {
        this.makeToast(response.data.isSuccess, 'Changes were successfully saved');
        this.getMailPatternList();
      }).catch(err => {
        if (err.response.status !== 401) {
          this.makeToast(false, 'An Error occurred. Try again later.')
          return;
        }

        this.makeToast(false, err.response.data.message);
      });
    },

    handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }

      this.addMailPattern();

      this.$nextTick(() => {
        this.$bvModal.hide('add-mail-pattern-modal')
      })
    },

    checkFormValidity() {
      const valid = this.$refs.form.checkValidity() && this.emailPattern.length > 3 && this.emailPattern.includes('.');
      this.emailPatternState = valid;
      return valid;
    },

    addMailPattern() {
      axios.put(config.mailPatternUpdateUrl.replace(/{pattern}/i, this.emailPattern), {
      }, {
        headers: {
          'Authorization': 'Bearer ' + this.$session.get('auth-token')
        }
      })
      .then(() => {
        this.makeToast(true, 'Data successfully saved!');
        this.getMailPatternList();
      })
      .catch(err => {
        if (err.response.status !== 401) {
          this.makeToast(false, err.response.data.message);
        }
      });
    },
  }
}
</script>
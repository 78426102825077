export default {
    modules: [
        {
            value: 'pipelines',
            text: 'Pipelines'
        },
        {
            value: 'summary',
            text: 'Summary'
        }
    ]
}
<template>
  <b-card title="Pipeline Status - Failed Pipelines" class="dashboard-pipelines-card">
    <hr />
    <b-card-body>
      <b-table
          :items="tableItems"
          :fields="tableFields"
          responsive="sm"
      >
        <template #thead-top="data">
          <b-tr>
            <b-th variant="primary" colspan="2">Last 7 Days</b-th>
            <b-th variant="primary" colspan="2" style="border-right: 1px solid; border-left: 1px solid">Last Month</b-th>
            <b-th variant="primary" colspan="2">All time</b-th>
          </b-tr>
        </template>
      </b-table>
    </b-card-body>
  </b-card>
</template>

<script>
import axios from "axios";
import config from "../../../../config/config";
import toast from "@/components/mixins/toast";
import moment from "moment/moment";
import {each, filter} from "lodash";

export default {
  name: "DashboardPipelines",

  mixins: [toast],

  data() {
    return {
      pipelineStatusData: null,
      tableItems: [],
      tableFields: [
        { key: 'master7Days', label: 'Master', tdClass: 'text-right', thClass: 'text-right'},
        { key: 'stage7Days', label: 'Stage', tdClass: 'text-right', thClass: 'text-right'},
        { key: 'master30Days', label: 'Master', tdClass: 'text-right', thClass: 'text-right'},
        { key: 'stage30Days', label: 'Stage', tdClass: 'text-right', thClass: 'text-right'},
        { key: 'masterAllTime', label: 'Master', tdClass: 'text-right', thClass: 'text-right'},
        { key: 'stageAllTime', label: 'Stage', tdClass: 'text-right', thClass: 'text-right'},
      ]
    }
  },

  async created() {
    await this.getPipelineStatus();
  },

  methods: {
    async getPipelineStatus() {
      this.pipelineStatusData = await this.performApiCall();
      this.prepareTableItems();
    },

    async performApiCall() {
      return axios.get(config.pipelineStatusUrl, {
        headers: {
          'Authorization': 'Bearer ' + this.$session.get('auth-token')
        }
      }).then(response => {
        this.loadingData = false;
        return response.data.projects;
      }).catch(err => {
        if (err.response.status !== 401) {
          this.makeToast(false, 'An Error occurred. Try again later.')
        }
        return [];
      });
    },

    prepareTableItems() {
      this.tableItems = [];

      this.tableItems.push({
        master7Days: this.getFailedPipelinesByDays(7, 'master'),
        stage7Days: this.getFailedPipelinesByDays(7, 'stage'),
        master30Days: this.getFailedPipelinesByDays(30, 'master'),
        stage30Days: this.getFailedPipelinesByDays(30, 'stage'),
        masterAllTime: this.getFailedPipelinesByDays(-1, 'master'),
        stageAllTime: this.getFailedPipelinesByDays(-1, 'stage'),
      });
    },

    getFailedPipelinesByDays(days, branch) {
      const projects = this.filterOutEmptyPipelines();
      let failedPipelines = this.getAllFailedPipelines(projects);

      return this.filterFailedPipelinesByDays(failedPipelines, days, branch);
    },

    filterOutEmptyPipelines() {
      return filter(this.pipelineStatusData, item => {
        return item.pipelines.length > 0;
      })
    },

    getAllFailedPipelines(projects) {
      let pipelines = [];

      each(projects, item => {
        each(item.pipelines, pipeline => {
          if (pipeline.status === 'failed') {
            pipelines.push(pipeline);
          }
        })
      });

      return pipelines;
    },

    filterFailedPipelinesByDays(pipelines, days, branch) {
      return filter(pipelines, item => {
        if (days === -1) {
          return item.branch === branch;
        }

        return item.branch === branch &&
            (moment(item.updated, 'DD.MM.YYYY HH:mm:ss') > moment().subtract(days, 'days'));
      }).length;
    }
  }
}
</script>